import { render, staticRenderFns } from "./light-index.vue?vue&type=template&id=7c5f35ac&"
import script from "./light-index.vue?vue&type=script&lang=ts&"
export * from "./light-index.vue?vue&type=script&lang=ts&"
import style0 from "./light-index.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\git.fe\\jtl3d-bi-admin\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7c5f35ac')) {
      api.createRecord('7c5f35ac', component.options)
    } else {
      api.reload('7c5f35ac', component.options)
    }
    module.hot.accept("./light-index.vue?vue&type=template&id=7c5f35ac&", function () {
      api.rerender('7c5f35ac', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/pages/sub-system/light-index.vue"
export default component.exports