var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "light-index-component" }, [
    _c("div", { staticClass: "page-header" }, [
      _c(
        "div",
        { staticClass: "page-breadcrumb" },
        [
          _c(
            "a-breadcrumb",
            [
              _c(
                "a-breadcrumb-item",
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/dashboard/light-index" } },
                    [_vm._v("照明管理")]
                  )
                ],
                1
              ),
              _c("a-breadcrumb-item", [_vm._v("概览")])
            ],
            1
          )
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "page-body page-body-margin" },
      [
        _c(
          "div",
          { staticClass: "parking-card-wrapper" },
          [
            _c(
              "a-row",
              {
                staticClass: "sub-system-header-card",
                attrs: {
                  type: "flex",
                  justify: "space-around",
                  align: "middle"
                }
              },
              _vm._l(_vm.headerCardList, function(item, index) {
                return _c(
                  "a-col",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: item.loading,
                        expression: "item.loading"
                      }
                    ],
                    key: index,
                    staticClass: "parking-card-item text-center"
                  },
                  [
                    _c("img", {
                      staticClass: "parking-card-item-icon",
                      attrs: { src: item.icon }
                    }),
                    _c(
                      "span",
                      {
                        staticClass: "parking-card-item-text-wrapper text-left"
                      },
                      [
                        _c("span", { staticClass: "parking-card-item-title" }, [
                          _vm._v(_vm._s(item.title))
                        ]),
                        _c("br"),
                        _c(
                          "span",
                          { staticClass: "parking-card-item-number" },
                          [_vm._v(_vm._s(_vm._f("text")(item.count)))]
                        ),
                        _c("span", { staticClass: "parking-card-item-unit" }, [
                          _vm._v(_vm._s(item.unit))
                        ])
                      ]
                    )
                  ]
                )
              }),
              1
            )
          ],
          1
        ),
        _c(
          "a-row",
          { attrs: { gutter: 16 } },
          [
            _c(
              "a-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "jtl-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.lightRateDataLoading,
                        expression: "lightRateDataLoading"
                      }
                    ],
                    staticClass: "parking-gauge-chart",
                    attrs: { title: "亮灯率统计" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "jtl-link",
                        attrs: {
                          slot: "extra",
                          to: "/dashboard/parking-chart/light-rate"
                        },
                        slot: "extra"
                      },
                      [_vm._v("查看")]
                    ),
                    _c(
                      "a-row",
                      {
                        staticClass: "parking-gauge-chart-content",
                        attrs: {
                          type: "flex",
                          align: "middle",
                          justify: "center"
                        }
                      },
                      [
                        _c(
                          "a-col",
                          { staticClass: "text-center", attrs: { span: 10 } },
                          [
                            _c("jtl-custom-gauge", {
                              staticClass: "inline-block",
                              attrs: {
                                width: 160,
                                "stroke-width": 15,
                                percent: _vm.lightRateData.rate,
                                "percent-desc": "亮灯率"
                              }
                            }),
                            _c(
                              "a-row",
                              {
                                staticClass: "parking-gauge-chart-text-wrapper",
                                attrs: { type: "flex", align: "middle" }
                              },
                              [
                                _c("a-col", { attrs: { span: 8 } }, [
                                  _c("span", { staticClass: "parking-title" }, [
                                    _vm._v("全部")
                                  ])
                                ]),
                                _c(
                                  "a-col",
                                  { attrs: { span: 16 } },
                                  [
                                    _c(
                                      "a-row",
                                      [
                                        _c(
                                          "a-col",
                                          {
                                            staticClass: "parking-desc",
                                            attrs: { span: 12 }
                                          },
                                          [_vm._v("总数")]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            staticClass:
                                              "parking-count text-right",
                                            attrs: { span: 12 }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("text")(
                                                  _vm.lightRateData.totalCount
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "a-row",
                                      [
                                        _c(
                                          "a-col",
                                          {
                                            staticClass: "parking-desc",
                                            attrs: { span: 12 }
                                          },
                                          [_vm._v("亮灯数")]
                                        ),
                                        _c(
                                          "a-col",
                                          {
                                            staticClass:
                                              "parking-count text-right",
                                            attrs: { span: 12 }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("text")(
                                                  _vm.lightRateData.freeCount
                                                )
                                              )
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "a-table",
                              {
                                staticClass: "parking-table",
                                attrs: {
                                  "children-column-name": "noChildren",
                                  "data-source": _vm.lightRateData.children,
                                  pagination: false,
                                  bordered: false,
                                  scroll: { y: 300 },
                                  size: "small"
                                }
                              },
                              [
                                _c("a-table-column", {
                                  key: "locationName",
                                  attrs: {
                                    title: "区域",
                                    "data-index": "locationName"
                                  }
                                }),
                                _c("a-table-column", {
                                  key: "totalCount",
                                  attrs: {
                                    title: "总数",
                                    "data-index": "totalCount"
                                  }
                                }),
                                _c("a-table-column", {
                                  key: "free",
                                  attrs: {
                                    title: "亮灯数",
                                    "data-index": "freeCount"
                                  }
                                }),
                                _c("a-table-column", {
                                  key: "rate",
                                  attrs: {
                                    title: "亮灯率",
                                    "data-index": "rate",
                                    "custom-render": function(text) {
                                      return _vm.percentFormat(text)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "jtl-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.facilityStatusDataLoading,
                        expression: "facilityStatusDataLoading"
                      }
                    ],
                    attrs: { title: "设备状态统计" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "jtl-link",
                        attrs: {
                          slot: "extra",
                          to: "/dashboard/parking-chart/light-facility-status"
                        },
                        slot: "extra"
                      },
                      [_vm._v("查看")]
                    ),
                    _c("jtl-ring", {
                      attrs: {
                        data: _vm.facilityStatusData,
                        settings: _vm.ChartRingSettings,
                        extend: _vm.ChartRingExtend,
                        "legend-visible": false
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        ),
        _c("br"),
        _c(
          "a-row",
          { attrs: { gutter: 16 } },
          [
            _c(
              "a-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "jtl-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.last7DayAlarmDataLoading,
                        expression: "last7DayAlarmDataLoading"
                      }
                    ],
                    attrs: { title: "近7天告警趋势" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "jtl-link",
                        attrs: {
                          slot: "extra",
                          to: "/dashboard/parking-chart/light-alarm-trend"
                        },
                        slot: "extra"
                      },
                      [_vm._v("查看")]
                    ),
                    _c("jtl-line", {
                      attrs: {
                        data: _vm.last7DayAlarmData,
                        "legend-visible": false
                      }
                    })
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "a-col",
              { attrs: { span: 12 } },
              [
                _c(
                  "jtl-card",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.electricityMonthDataLoading,
                        expression: "electricityMonthDataLoading"
                      }
                    ],
                    attrs: { title: "月度用电统计" }
                  },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "jtl-link",
                        attrs: {
                          slot: "extra",
                          to: "/dashboard/parking-chart/light-electricity-month"
                        },
                        slot: "extra"
                      },
                      [_vm._v("查看")]
                    ),
                    _c("jtl-histogram", {
                      attrs: {
                        data: _vm.electricityMonthData.data,
                        settings: _vm.electricityMonthData.settings,
                        "legend-visible": true
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }