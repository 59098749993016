










































































































import { Component, Mixins } from 'vue-property-decorator';
import { ReportChartLineComponent, ReportChartRingComponent } from '@/mixins/report-chart-base';
import LightIndexService from './service/light-index';
import { LightAlarmTrendQueryModel } from './model/light-alarm-trend';

@Component
export default class LightIndexComponent extends Mixins(ReportChartRingComponent, ReportChartLineComponent) {
    headerCardList: Array<any> = [
        { title: '今天用电量', count: null, unit: 'kWh', loading: true, icon: '/img/day-bar.png' },
        { title: '本月用电量', count: null, unit: 'kWh', loading: true, icon: '/img/month-bar.png' },
        { title: '设备总数', count: null, unit: '台', loading: true, icon: '/img/device-online.png' },
        { title: '故障设备', count: null, unit: '台', loading: true, icon: '/img/fault.png' },
        { title: '正在告警', count: null, unit: '条', loading: true, icon: '/img/alarm.png' }
    ];

    lightRateData: any = {};
    lightRateDataLoading: boolean = false;

    facilityStatusData: any = null;
    facilityStatusDataLoading: boolean = true;

    last7DayAlarmData: any = {};
    last7DayAlarmDataLoading: boolean = true;

    electricityMonthData: any = {};
    electricityMonthDataLoading: boolean = true;

    created() {
        this.initData();
    }

    initData() {
        LightIndexService.getElectricityCount().then(res => {
            this.headerCardList[0].count = res.dayCount;
            this.headerCardList[1].count = res.monthCount;
        }).finally(() => {
            this.headerCardList[0].loading = false;
            this.headerCardList[1].loading = false;
        });
        LightIndexService.getFacilityCount().then(res => {
            this.headerCardList[2].count = res.totalCount;
            this.headerCardList[3].count = res.faultCount;
        }).finally(() => {
            this.headerCardList[2].loading = false;
            this.headerCardList[3].loading = false;
        });
        LightIndexService.getAlarmActiveCount().then(res => {
            this.headerCardList[4].count = res.activeCount;
        }).finally(() => {
            this.headerCardList[4].loading = false;
        });

        LightIndexService.getFacilityStatusCount().then(res => {
            this.facilityStatusData = res;
        }).finally(() => this.facilityStatusDataLoading = false);

        const queryModel = new LightAlarmTrendQueryModel();
        LightIndexService.getLast7DayAlarmCount(queryModel).then(res => {
            this.last7DayAlarmData = res;
        }).finally(() => this.last7DayAlarmDataLoading = false);

        LightIndexService.getElectricityMonthData().then(res => {
            this.electricityMonthData = res;
        }).finally(() => this.electricityMonthDataLoading = false);
    }
}
